<template>
  <b-container class="pt-4 overflow-auto">
    <h1>{{ $t('castingEnter') }}</h1>

    <p class="text-center">{{ $t('enterRules') }}</p>   

    <b-row>
        <b-col cols="6" lg="3" class="mb-3">
            <b-btn class="w-100" 
                   @click="updateScore(1)" 
                   :disabled="gameWon || buttonClicks[1]">
               <img src="/emojis/pity.png" alt="жалость" class="w-100">
            </b-btn>
        </b-col>

        <b-col cols="6" lg="3" class="mb-3"> 
            <b-btn class="w-100" 
                   @click="updateScore(2)" 
                   :disabled="gameWon || buttonClicks[2]">
                <img src="/emojis/seduction.png" alt="соблазн" class="w-100">
            </b-btn>
        </b-col>

        <b-col cols="6" lg="3">
            <b-btn class="w-100" 
                   @click="updateScore(5)" 
                   :disabled="gameWon || buttonClicks[5]">
                <img src="/emojis/threats.png" alt="угрозы" class="w-100">
            </b-btn>
        </b-col>

        <b-col cols="6" lg="3">
            <b-btn class="w-100" 
                   @click="updateScore(10)" 
                   :disabled="gameWon || buttonClicks[10]">
                <img src="/emojis/lie.png" alt="обман" class="w-100">
            </b-btn>
        </b-col>
    </b-row>

    <p class="text-center mt-3 h3">
        {{ score }}
    </p>

    <p class="text-center" v-if="score === winScore">
        <b-badge variant="success">Победа!</b-badge>
    </p>

    <p class="text-center" v-if="score > winScore">
        <b-badge variant="danger">Перебор! Попробуйте снова</b-badge>
    </p>

    <div class="d-flex justify-content-center w-100 mt-3">
        <b-btn variant="primary" @click="resetGame">
            <span>Начать заново</span>
        </b-btn>
    </div>
  </b-container>
</template>


<script>
export default {
  name: 'CastingEnter',

  data() {
    return {
      winScore: 23,
      score: 0,
      buttonClicks: {
        1: false,
        2: false,
        5: false,
        10: false
      },
      lastClickedButton: null
    }
  },

  computed: {
    gameWon() {
      return this.score === this.winScore
    }
  },

  methods: {
    updateScore(points) {
      this.score += points
      
      if (this.lastClickedButton !== null) {
        this.buttonClicks[this.lastClickedButton] = false
      }
      
      this.buttonClicks[points] = true
      this.lastClickedButton = points
    },

    resetGame() {
      this.score = 0
      this.buttonClicks = {
        1: false,
        2: false,
        5: false,
        10: false
      }
      this.lastClickedButton = null
    }
  }
}
</script>